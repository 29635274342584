import React, { Suspense, useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import AppRoutes from "./routes/mainRoutes";
import "./styles/globals.scss";
import { useDispatch } from "react-redux";
import jwt from "./jwtservice/jwtService";
import { setCompanyLogo } from "./redux/app/appAction";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const getCompanyDetails = async () => {
      const { status, data } = await jwt.getCompanyDetailsPublic();
      if (status >= 200 && status < 300) {
        const { logoUrl } = data;
        dispatch(setCompanyLogo(logoUrl));
      } else {
        dispatch(setCompanyLogo(""));
      }
    };

    getCompanyDetails();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Suspense>
        <AppRoutes />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
