import { SET_COMPANY_LOGO } from "./appAction";

const initialState = {
  companyLogo: "",
};

const App = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_LOGO: {
      return {
        ...state,
        companyLogo: action.payload,
      };
    }
    case "RESET_STATE": {
      return {
        ...state,
        companyLogo: "",
      };
    }
    default: {
      return state;
    }
  }
};

export default App;
